import Footer from "../components/footer";
import Video from "../components/video";
import Why from "../components/whybtc";
import Article from "../components/article";


const WhyBitcoin = () => {

  return (
    <>
    
<Why></Why>
<br></br>
<Video></Video>
<br></br>
<Article></Article>
<Footer></Footer>
    
    </>
  );
};

export default WhyBitcoin;